<template>
  <order-form />
</template>

<script>
  import OrderForm from '../components/OrderForm'

  export default {
    name: 'Form',

    components: {
      OrderForm,
    },
  }
</script>
