<template>
  <v-container>
    <v-row class="text-center" dense justify="center">
      <v-col cols="12" lg="10" xl="8">
        <v-img
            src="img/kaesecaspar-titel.jpg"
            class="mb-5"
            fill
            height="40vw"
            max-height="300"
            position="center top"
            @click="$router.push('/')"
        />
      </v-col>
    </v-row>
    <template v-if="state==null">
      <v-row class="text-center" justify="center">
        <v-col cols="12" lg="10" xl="8">
          <v-btn color="secondary" class="text--primary" @click="$router.push('/')"><v-icon left>mdi-arrow-left</v-icon>zurück</v-btn>
        </v-col>
        <v-col cols="12" lg="10" xl="8">
          <h1 class="display-1 text-uppercase font-weight-bold mb-5">
            Warenkorb
          </h1>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="10" xl="8">
          <v-card outlined rounded v-for="(product) in productsInBasket" :key="product.id">
            <v-card-title style="height: 60px">{{ product.title}}</v-card-title>
            <v-card-text>
              <v-row no-gutters justify="center" align="center">
                <v-col cols="12" sm="9">
                  {{ product.price }}<br>{{ product.item }}</v-col>
                <v-col cols="12" sm="2">
                  <v-select
                      v-model="product.quantity"
                      dense
                      label="Menge"
                      :items="[1,2,3,4,5,6,7,8,9,10,'mehr']"
                      outlined
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="1">
                  <v-btn fab text @click="$store.dispatch('removeProduct', product.id)"><v-icon color="red">mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <!--          <hr color="#ccc" style="height: 1px; border: 0px; width: 70%; margin: 0 auto" v-if="index < productsInBasket.length - 1">-->
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="10" xl="8">
          <v-card outlined>
            <v-card-text>
              <div>Versandkosten</div>
              <div class="caption" v-html="settings.orderform_delivery_at"></div>
              <div class="caption" v-html="settings.orderform_delivery_de"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="10" xl="8">
          <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      background-color="white"
                      v-model="firstname"
                      :rules="nameRules"
                      label="Vorname"
                      outlined
                      required
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      background-color="white"
                      v-model="lastname"
                      :rules="nameRules"
                      label="Nachname"
                      outlined
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      background-color="white"
                      v-model="street"
                      :rules="nameRules"
                      label="Strasse"
                      outlined
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      background-color="white"
                      v-model="zip"
                      :rules="nameRules"
                      label="PLZ"
                      outlined
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      background-color="white"
                      v-model="city"
                      :rules="nameRules"
                      label="Stadt"
                      outlined
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-select
                      background-color="white"
                      v-model="country"
                      :rules="nameRules"
                      label="Land"
                      :items="['Österreich','Deutschland','Schweiz','Liechtenstein','weitere Länder']"
                      outlined
                      required
                  ></v-select>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      background-color="white"
                      v-model="phone"
                      :rules="nameRules"
                      label="Telefon"
                      outlined
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      background-color="white"
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      outlined
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                >
                  <v-textarea
                      background-color="white"
                      v-model="message"
                      label="Nachricht"
                      outlined
                  ></v-textarea>
                </v-col>
              </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="text-center pb-10" justify="center">
        <v-col cols="12" lg="10" xl="8" v-if="error">
          <v-alert  color="red" type="error" icon="mdi-alert" dense>
            <div>Fehler: {{ error }}</div>
          </v-alert>
        </v-col>
        <v-col cols="12" lg="10" xl="8">
          <v-btn color="primary" large :loading="loading" :disabled="!valid" @click="sendForm">Anfrage absenden<v-icon right>mdi-send</v-icon></v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="state=='thankyou'">
      <v-row class="text-center" justify="center">
        <v-col cols="12" lg="10" xl="8">
          <v-btn color="secondary" class="text--primary" @click="$router.push('/')"><v-icon left>mdi-arrow-left</v-icon>zurück</v-btn>
        </v-col>
        <v-col cols="12" lg="10" xl="8">
          <h1 class="display-1 text-uppercase font-weight-bold mb-5">
            Vielen Dank!
          </h1>
          <div>
            Vielen Dank für Ihre Anfrage. Ich werde mich schnellstmöglich bei Ihnen melden.
          </div>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "OrderForm",
  props: ["title"],
  computed: {
    ...mapState({
      products: state => state.products,
      basket: state => state.basket,
      settings: state => state.settings
    }),
    productsInBasket() {
      const prods = []
      this.basket.forEach(itemInBasket => {
        prods.push(this.products.find(item => item.id == itemInBasket.id))
      })
      return prods
    }
  },
  data: () => ({
    state: null,
    loading: false,
    error: '',
    valid: false,
    firstname: '',
    lastname: '',
    city: '',
    zip: '',
    street: '',
    country: '',
    message: '',
    phone: '',
    nameRules: [
      v => !!v || 'Pflichtfeld'
    ],
    email: '',
    emailRules: [
      v => !!v || 'Pflichtfeld',
      v => /.+@.+/.test(v) || 'E-mail ungültig',
    ],
  }),
  methods: {
    sendForm () {
      const form = this.$refs.form
      if (form.validate()) {

        let formData = new FormData()
        formData.append('fromFirstname', this.firstname)
        formData.append('fromLastname', this.lastname)
        formData.append('fromEmail', this.email)
        formData.append('fromPhone', this.phone)
        formData.append('fromStreet', this.street)
        formData.append('fromZip', this.zip)
        formData.append('fromCity', this.city)
        formData.append('fromCountry', this.country)
        formData.append('products', JSON.stringify(this.productsInBasket))
        formData.append('message', this.message)

        console.log('formdata', formData)

        this.error = ''
        this.loading = true

        this.$store.dispatch('sendRequest', formData)
        .then((result) => {
            this.state = 'thankyou'
            console.log('success', result.data)
        })
        .catch((error) => {
          this.error = error
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
